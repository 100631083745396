<template>
  <v-form ref="form" v-model="valid">
    <v-row dense>
      <v-col cols="12">
        <sf-password-field
          ref="password1"
          v-model="password"
          :label="$t('password')"
          hide-details="auto"
          :rules="[rules.passwordLengthRule, rules.passwordRule]"
          autocomplete="new-password"
          @keydown.enter="submit"
        />
      </v-col>
      <v-col cols="12">
        <sf-password-field
          ref="password2"
          v-model="passwordRepeat"
          :label="$t('passwordRepeat')"
          :rules="[rules.passwordMatchRule(password)]"
          required
          hide-details="auto"
          autocomplete="new-password"
          @keydown.enter="submit"
        />
      </v-col>
    </v-row>

    <sf-large-button :disabled="!valid" block class="mt-2" :loading="sending" @click="submit">
      {{ $t('setPassword') }}
    </sf-large-button>
  </v-form>
</template>

<script lang="ts">
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfPasswordField from '@/components/input/SfPasswordField.vue'
import { validateForm } from '@/helpers'
import useSelfRegistration from '@/modules/authentication/useSelfRegistration'
import rules from '@/rules'
import { VForm } from '@/types'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SelfRegistrationPassword',
  components: { SfLargeButton, SfPasswordField },
  emits: ['submit'],
  setup(_, { emit }) {
    const { sending } = storeToRefs(useSelfRegistration())

    const form = ref<VForm>()
    const password = ref('')
    const passwordRepeat = ref('')
    const valid = ref(false)

    async function submit() {
      if (await validateForm(form)) {
        emit('submit', password.value)
      }
    }

    return {
      form,
      password,
      passwordRepeat,
      rules,
      valid,
      submit,
      sending
    }
  }
})
</script>
