<template>
  <v-card :class="[{ 'border--red': !addressValidation.valid }]" height="100%">
    <v-card-text>
      <div class="d-flex flex-wrap">
        <quote-address v-if="address" class="flex-grow-1" :address="address" />
        <div class="d-flex flex-column flex-grow-1">
          <order-contact-data v-if="contactData" :contact-data="contactData" />
          <div v-if="customerNumber" class="mt-1">
            <sf-sub-heading>
              {{ $t('clientNumber') }}
            </sf-sub-heading>
            <sf-text>
              {{ customerNumber }}
            </sf-text>
          </div>
        </div>
        <quote-address-and-contact-data-menu
          v-if="editable && addressSelection"
          ref="menu"
          :readonly="isReadonly"
          :address-selection="addressSelection"
          :selected-address="address"
          :contact-data="contactData"
          :show-select-hint="isGuest ? false : portalSettings.useOwnDeliveryAddress"
          @create="createAddress"
          @select="selectAddress"
          @save="saveAddress"
          @save-contact-data="updateContactData"
        />
      </div>
      <div v-if="!addressValidation.valid && addressValidation.error" class="mt-2">
        <sf-color-text color="red">{{ addressValidation.error }}</sf-color-text>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import SfColorText from '@/components/text/SfColorText.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { AddressSelection, ContactData, ShopAddress } from '@/generatedTypes'
import { isGuest } from '@/helpers'
import { AddressType } from '@/modules/address/types'
import useAddress from '@/modules/address/useAddress'
import useAddressValidation from '@/modules/address/useAddressValidation'
import useAuthentication from '@/modules/authentication/useAuthentication'
import OrderContactData from '@/modules/order/components/OrderContactData.vue'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import QuoteAddress from '@/modules/quote/components/QuoteAddress.vue'
import QuoteAddressAndContactDataMenu from '@/modules/quote/components/QuoteAddressAndContactDataMenu.vue'
import { storeToRefs } from 'pinia'
import { PropType, computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'QuoteAddressAndContactData',
  components: {
    OrderContactData,
    QuoteAddress,
    QuoteAddressAndContactDataMenu,
    SfText,
    SfSubHeading,
    SfColorText
  },
  props: {
    address: {
      type: Object as PropType<ShopAddress | undefined>,
      required: true
    },
    addressSelection: {
      type: Object as PropType<AddressSelection>,
      default: undefined
    },
    contactData: {
      type: Object as PropType<ContactData | undefined>,
      default: undefined
    },
    customerNumber: {
      type: String,
      default: undefined
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  emits: ['selectAddress', 'updateAddress', 'updateContactData'],
  setup(_, { emit }) {
    const { portalSettings } = storeToRefs(usePortalSettings())

    const addressValidation = useAddressValidation()[AddressType.delivery]
    const isReadonly = computed(() => useAuthentication().state.value.isReadonly)

    const selectAddress = (address: ShopAddress) => {
      emit('selectAddress', address.id)
    }

    const createAddress = (address: ShopAddress): void => {
      useAddress()
        .createAddress(address)
        .then(() => {
          emit('selectAddress', address.id)
        })
    }

    const saveAddress = (address: ShopAddress): void => {
      emit('updateAddress', address)
    }

    const updateContactData = (updatedContactData: ContactData): void => {
      emit('updateContactData', updatedContactData)
    }

    return {
      portalSettings,
      isGuest,
      addressValidation,
      isReadonly,
      selectAddress,
      createAddress,
      saveAddress,
      updateContactData
    }
  }
})
</script>
