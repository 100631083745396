import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import { CustomTerms, SelfRegistration } from '@/generatedTypes'
import { EmailStatus } from '@/modules/user/types'
import api from '@/modules/authentication/api'
import { Step } from '@/modules/authentication/types'
import router from '@/router'
import helpers from '@/modules/message/helpers'

interface SelfRegistrationState {
  customTerms: CustomTerms
  loading: boolean
  sending: boolean
}

const initialState = (): SelfRegistrationState => {
  return {
    loading: false,
    sending: false,
    customTerms: {
      isCustomTerms: false,
      terms: ''
    }
  }
}

const useSelfRegistration = defineStore('selfRegistration', {
  state: () => initialState(),
  actions: {
    async getCustomTerms(lang: string) {
      this.loading = true

      return api
        .getCustomTerms(lang)
        .then(({ data }) => {
          this.customTerms = data
        })
        .catch(handleApiError)
        .finally(() => (this.loading = false))
    },
    async validateEmail(email: string): Promise<EmailStatus | void> {
      this.sending = true

      return api
        .validateRegistrationEmail(email)
        .then(({ data }) => data)
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.invitation'
          })
        })
        .finally(() => (this.sending = false))
    },
    async performSelfRegistration(data: SelfRegistration) {
      this.sending = true

      return api
        .executeSelfRegistration(data)
        .then(() => {
          helpers.reportSuccess('success.registrationComplete')
          router.replace('/login')
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.registration'
          })
        })
        .finally(() => (this.sending = false))
    },
    async requestSelfRegistration(data: SelfRegistration, setStepCallback: (step: Step) => void) {
      this.sending = true

      return api
        .executeSelfRegistration(data)
        .then(() => {
          helpers.reportSuccess('success.registrationRequested')
          router.replace({
            name: 'SelfRegistration',
            params: { step: Step.REQUESTED }
          })
          setStepCallback(Step.REQUESTED)
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.registration'
          })
        })
        .finally(() => (this.sending = false))
    }
  }
})

export default useSelfRegistration
