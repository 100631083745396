/** AccessoryGroup */
type AccessoryGroup = {
  groupName: string
  products: MediumProduct[]
}
/** AddressPermissions */
type AddressPermissions = {
  canCreate: boolean
  canSetDefaultBillingAddress: boolean
  canSetDefaultDeliveryAddress: boolean
}
/** AddressSelection */
type AddressSelection = {
  availableAddresses: ShopAddressRes[]
  canAdd: boolean
}
/** ApprovalRequest */
type ApprovalRequest = {
  /** @description List of users who are assigned to approve this request */
  assignees: ShortUserRes[]
  /** @description Date this request was created */
  createdDate: string
  /** @description Date this request was processed by the processor */
  processedDate?: string
  /** @description User who answered the request */
  processor?: ShortUserRes
  /** @description Comment of the user who processed the request */
  processorComment?: string
  /** @description Comment of the user who initiated the request */
  requestComment?: string
  /**
   * @description The current status of the request
   * @enum {string}
   */
  status: ApprovalRequestStatus
}
/** ArticleNumber */
type ArticleNumber = {
  additionalId?: string
  ean?: string
  manufacturerProductId?: string
}
/** Attachment */
type Attachment = {
  content: string
  /** @enum {string} */
  contentType: ContentType
  name: string
}
/** AuthTokens */
type AuthTokens = {
  accessToken: string
  refreshToken: string
}
/** BaseModelReq */
type BaseModelReq = {
  baseModelPicUrl?: string
  description: string
  descriptionTwo: string
  ean: string
  manufacturerMaterialNo: string
  materialNo: string
  /** Format: int32 */
  modelId?: number
}
/** BaseModelRes */
type BaseModelRes = {
  baseModelPicUrl?: string
  description: string
  descriptionTwo: string
  ean: string
  manufacturerMaterialNo: string
  materialNo: string
  /** Format: int32 */
  modelId: number
}
/** BaseModelResource */
type BaseModelResource = {
  baseModel: BaseModelRes
  selectedModelComponents: ModelComponentRes[]
}
/** Branding */
type Branding = {
  backgroundImageUrl?: string
  faviconUrl?: string
  /**
   * @description True if this portal is currently paused e.g. due to maintenance
   * @example false
   */
  isPaused: boolean
  /**
   * @description Enables registration for new employees for an existing customer
   * @example false
   */
  isUserRegistration: boolean
  /** @description Url to further legal info */
  legalInfoUrl?: string
  logoUrl?: string
  ownerName: string
  /** @description Text shown when portal is paused */
  pausedText?: string
  portalName: string
  primaryColorHexCode?: string
  /** @description Url to GDRR */
  privacyUrl?: string
  ssoSettings?: SSOSettings
  /** @description Url to the terms and conditions */
  termsUrl?: string
}
/** BundleItemProduct */
type BundleItemProduct = {
  articleNumber: ArticleNumber
  datasheet: Datasheet
  description: string[]
  imageUrls: string[]
  /** @description Date in ISO-8601 format in UTC timezone */
  listedSince: string
  manufacturer: Manufacturer
  /** Format: int64 */
  productId: number
  productType: ProductType
  technicalDetails: TechnicalDetail[]
  title: string
}
/** BundleReference */
type BundleReference = {
  /** Format: int32 */
  amount: number
  product: BundleItemProduct
}
/** Cart */
type Cart = {
  /**
   * @description Denotes whether the user is allowed to order.
   * @enum {string}
   */
  canOrder: CanOrder
  cartInfo: CartInfo
  /** @description Explanations why ordering may be forbidden and other warnings for the user. */
  messages: CartMessage[]
  supplierCarts: SupplierCart[]
  /** @description ITscope-generated timestamp to be used for cart updates */
  timestamp: string
}
/** CartInfo */
type CartInfo = {
  /** @description Sum of item costs, excluding shipping and surcharge. */
  itemCost: Money
  /** @description Surcharge for small quantities. */
  quantitySurcharge?: Money
  shippingCost?: Money
  /** @description Shopping cart total. Contains shipping and surcharge. */
  totalCost: Money
  totalTax: Money
  /**
   * Format: bigdecimal
   * @description Value added tax percentage e.g. 19 for germany
   */
  vatPercent: number
}
/** CartItem */
type CartItem = {
  articleNumber?: ArticleNumber
  /** @enum {string} */
  condition?: Condition
  id: string
  price?: SimplePrice
  /** Format: int64 */
  productId: number
  productImageUrl?: string
  productTitle?: string
  /** Format: int32 */
  quantity: number
  sellerComments: CartItemSellerCommentRes
  stock?: SimpleStock
  supplierComment?: string
}
/** CartItemMoveDTO */
type CartItemMoveDTO = {
  toSupplierCartId: string
}
/** CartItemMoveInfo */
type CartItemMoveInfo = {
  /** @description Maps each cart item serial to a list of available supplier cart serials where the item can be moved to */
  availableSupplierCarts: {
    [key: string]: string[]
  }
}
/** CartItemPostDTO */
type CartItemPostDTO = {
  id?: string
  /** Format: int64 */
  productId: number
  /** Format: int32 */
  quantity: number
  /** Format: int64 */
  supplierRowId?: number
}
/** CartItemSellerCommentReq */
type CartItemSellerCommentReq = {
  field1?: string
  field2?: string
  field3?: string
  field4?: string
  field5?: string
  field6?: string
}
/** CartItemSellerCommentRes */
type CartItemSellerCommentRes = {
  field1?: string
  field2?: string
  field3?: string
  field4?: string
  field5?: string
  field6?: string
}
/** CartItemsCount */
type CartItemsCount = {
  /** Format: int32 */
  count: number
}
/** CartMessage */
type CartMessage = {
  message: string
  /** @enum {string} */
  reason: Reason
  /** @enum {string} */
  type: CartMessageType
}
/** CartOptimizationResult */
type CartOptimizationResult = {
  /** @description Difference in sum of optimized cart to the current cart. */
  difference: Money
  /** @enum {string} */
  optimizationStrategy: OptimizationStrategy
  /** @description Total sum of cart for this optimization. */
  sum: Money
}
/** CartPatchDTO */
type CartPatchDTO = {
  /** Format: int32 */
  quantity?: number
  sellerComments?: CartItemSellerCommentReq
  supplierComment?: string
  /** @description Use ITscope-generated timestamp of the cart you want to update */
  timestamp: string
}
/** Category */
type Category = {
  /** @description List of subcategories */
  children: Category[]
  /** Format: int32 */
  id: number
  /**
   * @description Is true if category has any own products or includes products of subcategories
   * @example false
   */
  isSelectable: boolean
  name: string
  /** @description Term to be used as a filter in product search */
  searchTerm: string
}
/** ChangePasswordDTO */
type ChangePasswordDTO = {
  password: string
  token: Token
}
/** Checkout */
type Checkout = {
  billingAddress: CheckoutAddress
  /** @description Customer number is assigned by the portal owner */
  customerNumber: string
  deliveryAddress: CheckoutAddress
  /** @description List of users who can approve the order */
  orderLimitApprovers: ShortUser[]
  /**
   * @description Flag denoting if the order limit is exceeded
   * @enum {string}
   */
  orderLimitExceedance: OrderLimitExceedance
  /** @description Order limit, if available */
  orderLimitValue?: Money
  /** @description Order number defined by the buyer or generated manually. Unique between buyer and seller. */
  orderNumber?: string
}
/** CheckoutAddress */
type CheckoutAddress = {
  availableAddresses: ShopAddress[]
  canAdd: boolean
  default?: ShopAddress
}
/** CheckoutAddressValidation */
type CheckoutAddressValidation = {
  error?: string
  valid: boolean
}
/** CollectionId */
type CollectionId = {
  id: string
}
/** CollectionItem */
type CollectionItem = {
  articleNumber?: ArticleNumber
  comment?: string
  id: string
  price?: SimplePrice
  /** Format: int64 */
  productId: number
  productImageUrl?: string
  productTitle?: string
  /** Format: int32 */
  quantity: number
  stock?: SimpleStock
}
/** CollectionItemPatchDTO */
type CollectionItemPatchDTO = {
  comment?: string
  /** Format: int32 */
  quantity?: number
}
/** CollectionItemPostDTO */
type CollectionItemPostDTO = {
  /** Format: int64 */
  productId: number
}
/** CollectionName */
type CollectionName = {
  name: string
}
/** CollectionPatchDTO */
type CollectionPatchDTO = {
  assignedUserId?: string
  description?: string
  name?: string
}
/** Comment */
type Comment = {
  /** Format: int32 */
  maxLength: number
  value?: string
}
/** CommentDTO */
type CommentDTO = {
  value: string
}
/** CommentField */
type CommentField = {
  label?: string
  required: boolean
}
/** Company */
type Company = {
  city: string
  country: string
  name: string
  phone: string
  street: string
  zipCode: string
}
/** Contact */
type Contact = {
  /** @description 2 letter language code according to ISO 639-1 */
  alpha2language: string
  /** @enum {string} */
  contactTitle: Title
  email: string
  firstName: string
  lastName: string
}
/** ContactData */
type ContactData = {
  email: string
  firstName: string
  lastName: string
  phone?: string
  phoneMobile?: string
}
/** ContactDataReq */
type ContactDataReq = {
  email: string
  firstName: string
  lastName: string
  phone?: string
  phoneMobile?: string
}
/** ContactDataRes */
type ContactDataRes = {
  email: string
  firstName: string
  lastName: string
  phone?: string
  phoneMobile?: string
}
/** ContactUser */
type ContactUser = {
  avatarUrl?: string
  email: string
  firstName: string
  lastName: string
  phone?: string
  phoneMobile?: string
}
/** CustomTerms */
type CustomTerms = {
  /**
   * @description True if custom terms apply
   * @example false
   */
  isCustomTerms: boolean
  /** @description Custom terms can be defined on ITscope platform. */
  terms?: string
}
/** Datasheet */
type Datasheet = {
  /** @description URL to the manufacturer's datasheet */
  manufacturerUrl?: string
}
/** DefaultOrderLimit */
type DefaultOrderLimit = {
  activated: boolean
  /**
   * Format: bigdecimal
   * @description Company specific order limit
   */
  companyOrderLimit?: number
  defaultApprovers: ShortUser[]
}
/** DefaultOrderLimitPatch */
type DefaultOrderLimitPatch = {
  activated?: boolean
  approverIds?: string[]
  /**
   * Format: bigdecimal
   * @description Company specific order limit
   */
  defaultOrderLimit?: number
}
/** DeliveryInfo */
type DeliveryInfo = {
  carrier: string
  trackingLinks: TrackingLink[]
}
/** DetailedOrder */
type DetailedOrder = {
  approvalRequest?: ApprovalRequest
  billingAddress: OrderAddress
  billingAddressSelection?: AddressSelection
  billingContactData?: ContactDataRes
  /** @description Customer number is assigned by the portal owner */
  customerNumber: string
  /** @description End customer order id defined by the buyer */
  customerOrderId?: string
  deliveryAddress?: OrderAddress
  deliveryAddressSelection?: AddressSelection
  deliveryContactData?: ContactDataRes
  distributorNames: string[]
  hasPartialShipment: boolean
  /** @description ITscope generated order id */
  id: string
  initiator: ShortUserRes
  isArchived: boolean
  /** Format: int32 */
  itemCount: number
  items: OrderItem[]
  lastUpdated: string
  orderComment?: string
  orderLimitApprovers?: ShortUserRes[]
  /** @enum {string} */
  orderLimitExceedance?: OrderLimitExceedance
  orderLimitValue?: Money
  /** @description Order number defined by the buyer or generated manually. Unique between buyer and seller. */
  orderNumber: string
  /** @enum {string} */
  orderStatus: OrderStatus
  orderSumInformation: OrderSumInformation
  quantitySurcharge?: Money
  sentDate?: string
  shippingCost: Money
  /** @description ITscope-generated timestamp to be used for order updates */
  timestamp: string
}
/** DetailedShopCollection */
type DetailedShopCollection = {
  assignee?: ShortUser
  /** @description Date in ISO-8601 format in UTC timezone */
  createdDate: string
  description?: string
  id: string
  isArchived: boolean
  items: CollectionItem[]
  /** @description Date in ISO-8601 format in UTC timezone */
  lastModifiedDate?: string
  name: string
}
/** Discount */
type Discount = {
  percentual: boolean
  value: Money
}
/** Email */
type Email = {
  email: string
}
/** EmailPostDTO */
type EmailPostDTO = {
  email: string
}
/** EmailPreview */
type EmailPreview = {
  attachments: Attachment[]
  body: string
  subject: string
}
/** EmailsPostDTO */
type EmailsPostDTO = {
  emails: string[]
}
/** EmailsResponse */
type EmailsResponse = {
  blacklistedEmails: string[]
  invalidEmails: string[]
  isAlreadyInvited: string[]
  isAlreadyRegistered: string[]
  isValid: boolean
}
/** FilterIdentifier */
type FilterIdentifier = {
  name: string
  values: FilterValue[]
}
/** FilterValue */
type FilterValue = {
  /** Format: int32 */
  count?: number
  name: string
  term: string
}
/** FluxOfServerSentEventOfListOfQuoteActivity */
type FluxOfServerSentEventOfListOfQuoteActivity = {
  /** Format: int32 */
  prefetch?: number
}
/** FluxOfServerSentEventOfQuote */
type FluxOfServerSentEventOfQuote = {
  /** Format: int32 */
  prefetch?: number
}
/** FullProduct */
type FullProduct = {
  articleNumber: ArticleNumber
  /** @enum {string} */
  condition?: Condition
  datasheet: Datasheet
  description: string[]
  imageUrls: string[]
  isBundle: boolean
  /** @description Date in ISO-8601 format in UTC timezone */
  listedSince: string
  manufacturer: Manufacturer
  manufacturerSuggestedRetailPrice?: Money
  price?: SimplePrice
  productDescription: TextContent[]
  /** Format: int64 */
  productId: number
  productType: ProductType
  stock?: SimpleStock
  suppliers: SupplierRow[]
  technicalDetails: TechnicalDetail[]
  title: string
}
/** FullQuote */
type FullQuote = {
  alpha2language: string
  buyerAddress?: ShopAddressRes
  buyerAddressSelection?: AddressSelection
  /** @description Customer number is assigned by the portal owner */
  buyerCustomerNumber?: string
  buyerUser?: ContactUser
  /** @description Date in ISO-8601 format in UTC timezone */
  createdDate: string
  footerText?: string
  headerText?: string
  id: string
  isArchived: boolean
  isGrossQuote: boolean
  /** Format: int32 */
  itemCount: number
  items: QuoteItem[]
  name: string
  quoteNumber: string
  quoteVersion: string
  rejectionInformation?: RejectionInformation
  sellerUser?: ContactUser
  sumInformation: OrderSumInformation
  /** @description Date in ISO-8601 format in UTC timezone */
  validDate?: string
}
/** ImpersonationStatus */
type ImpersonationStatus = {
  /** @description Name of the portal owner */
  customer: string
  /** @enum {string} */
  relation: Relation
}
/** InvitationRegistration */
type InvitationRegistration = {
  /** @description 2 letter language code according to ISO 639-1 */
  alpha2language: string
  /** @enum {string} */
  contactTitle: Title
  customTermsConfirmation: boolean
  firstName: string
  lastName: string
  password: string
  termsConfirmation: boolean
  token: Token
}
/** Manufacturer */
type Manufacturer = {
  /** Format: int64 */
  id: number
  name: string
}
/** MediumProduct */
type MediumProduct = {
  articleNumber: ArticleNumber
  /** @enum {string} */
  condition?: Condition
  description: string[]
  imageUrl?: string
  /** @description Can be null, and will be if quick=true */
  price?: SimplePrice
  /** Format: int64 */
  productId: number
  productType: ProductType
  /** @description Can be null, and will be if quick=true */
  stock?: SimpleStock
  /** @description Can be empty, and will be if quick=true */
  suppliers: SupplierRow[]
  title: string
}
/** ModelComponentGroup */
type ModelComponentGroup = {
  description: string
  /** Format: int32 */
  groupId: number
  modelComponents: SelectableModelComponent[]
}
/** ModelComponentReq */
type ModelComponentReq = {
  /** Format: int32 */
  componentId?: number
  /** Format: double */
  componentListPrice?: number
  description: string
}
/** ModelComponentRes */
type ModelComponentRes = {
  /** Format: int32 */
  componentId: number
  /** Format: double */
  componentListPrice: number
  description: string
}
/** ModelConfiguration */
type ModelConfiguration = {
  baseModel: BaseModelRes
  fixedModelComponents: ModelComponentRes[]
  modelComponentGroups: ModelComponentGroup[]
}
/** ModelGroup */
type ModelGroup = {
  modelGroupDescription: string
  /** Format: int32 */
  modelGroupId: number
  modelGroupPicUrl: string
  /** Format: int32 */
  modelParentGroupId: number
  productClassPicUrl: string
}
/** ModelGroupResource */
type ModelGroupResource = {
  baseModels: BaseModelResource[]
  modelGroup: ModelGroup
}
/** Money */
type Money = {
  /** @description ISO 4217 three letter currency symbol, e.g. EUR */
  currency: string
  /**
   * Format: bigdecimal
   * @description Amount of currency units with two decimal places
   */
  value: number
}
/** NewQuotesCount */
type NewQuotesCount = {
  /** Format: int32 */
  count: number
}
/** OptimizationType */
type OptimizationType = {
  customName?: string
  /** @enum {string} */
  optimizationStrategy: OptimizationStrategy
}
/** Order */
type Order = {
  /** @description Names of distributors used in this order. Is empty, if suppliers are not visible. */
  distributorNames: string[]
  /** @description ITscope generated order id */
  id: string
  /** @description Full name of user who initiated the order */
  initiatorFullName: string
  isArchived: boolean
  /** Format: int32 */
  itemCount: number
  lastUpdated: string
  /** @description Order number defined by the buyer or generated manually. Unique between buyer and seller. */
  orderNumber: string
  /** @enum {string} */
  orderStatus: OrderStatus
  sentDate?: string
  sum: Money
}
/** OrderAddress */
type OrderAddress = {
  addition1?: string
  addition2?: string
  city: string
  clientNumber?: string
  companyName: string
  email?: string
  /**
   * @description Country code according to the ISO 3166-1 alpha 3 standard.
   * @example DEU
   */
  iso3country: string
  /**
   * @description Telephone number headed by country code.
   * @example +49 123456789
   */
  phone?: string
  street: string
  url?: string
  zipCode: string
}
/** OrderItem */
type OrderItem = {
  articleNumber?: ArticleNumber
  comment: Comment
  deliveryInfo?: DeliveryInfo
  id: string
  isInAssortment: boolean
  packagingModel?: PackagingModel
  price?: Money
  /** Format: int64 */
  productId?: number
  productImageUrl?: string
  productTitle: string
  /** Format: int32 */
  quantity: number
  sellerComment: Comment
  serialNumbers: SerialNumber[]
  subscriptionModel?: SubscriptionModel
  supplierName: string
}
/** OrderLimitUser */
type OrderLimitUser = {
  approvers: ShortUser[]
  avatarUrl?: string
  firstName: string
  id: string
  lastName: string
  /** @enum {string} */
  orderLimitType: OrderLimitType
  /**
   * @description Determines if this user has the right to order
   * @example false
   */
  submitOrder: boolean
  /**
   * Format: bigdecimal
   * @description User specific order limit
   */
  userOrderLimit?: number
}
/** OrderLimitUserPatch */
type OrderLimitUserPatch = {
  approverIds: string[]
  /** @enum {string} */
  orderLimitType: OrderLimitType
  /**
   * @description Determines if this user has the right to order
   * @example false
   */
  submitOrder: boolean
  /**
   * Format: bigdecimal
   * @description User specific order limit
   */
  userOrderLimit?: number
}
/** OrderPostDTO */
type OrderPostDTO = {
  billingAddress: ShopAddress
  billingContactData?: ContactData
  /** @description End customer order id defined by the buyer */
  customerOrderId?: string
  deliveryAddress: ShopAddress
  deliveryContactData?: ContactData
  orderComment?: string
  /** @description Order number defined by the buyer or generated manually. Unique between buyer and seller. */
  orderNumber?: string
  /** @description Use ITscope-generated timestamp of the order you want to update */
  timestamp: string
  usePartialShipment?: boolean
}
/** OrderPreview */
type OrderPreview = {
  /** @description Names of distributors used in this order. Is empty, if suppliers are not visible. */
  distributorNames: string[]
  /** @description ITscope generated order id */
  id: string
  /** @description User who initiated the order */
  initiatorUser: ShortUserRes
  items: OrderItem[]
  /** @description Order number defined by the buyer or generated manually. Unique between buyer and seller. */
  orderNumber: string
  /** @enum {string} */
  orderStatus: OrderStatus
  orderSumInformation: OrderSumInformation
  sentDate?: string
  shippingCost: Money
}
/** OrderRejectionDTO */
type OrderRejectionDTO = {
  comment: string
}
/** OrderResponse */
type OrderResponse = {
  /** @description ITscope generated order id */
  id: string
  /** @description Order number defined by the buyer or generated manually. Unique between buyer and seller. */
  orderNumber: string
}
/** OrderSearchModel */
type OrderSearchModel = {
  /** @description ITscope generated order id */
  id: string
  initiatorFullName: string
  /** Format: int32 */
  itemCount: number
  lastUpdated: string
  /** @description Order number defined by the buyer or generated manually. Unique between buyer and seller. */
  orderNumber: string
  /** @enum {string} */
  orderStatus: OrderStatus
}
/** OrderSumInformation */
type OrderSumInformation = {
  grossSum: Money
  netDiscount?: Money
  netSum: Money
  netSumWithoutDiscount: Money
  totalTaxes: TotalTaxInfo[]
}
/** OrderTransaction */
type OrderTransaction = {
  date: string
  documentNumber?: string
  hasPdfDocument: boolean
  id: string
  /** @enum {string} */
  status: OrderStatus
  /** @enum {string} */
  transactionType: TransactionType
}
/** OrderTransactions */
type OrderTransactions = {
  orderId: string
  transactions: OrderTransaction[]
}
/** OrderingConditions */
type OrderingConditions = {
  /**
   * @description Flag indicates if the customer needs to enter ann order id to order
   * @example false
   */
  customerOrderIdRequired: boolean
  isPartialShipmentEnabled: boolean
  /**
   * @description Flag indicates if Karla-Sofort-Payment is enabled
   * @example false
   */
  isSofortPaymentEnabled: boolean
  /**
   * Format: int32
   * @description Maximum length for comments
   */
  maxCommentLength: number
  /**
   * @description Flag indicates if customer order id should be shown
   * @example false
   */
  showCustomerOrderId: boolean
}
/** PackagingModel */
type PackagingModel = {
  /** @description Unit of the contents of an order, e.g "bottle". */
  contentUnit?: string
  /**
   * Format: int32
   * @description The amount to be ordered between different single orders.
   */
  intervalQuantity: number
  /**
   * Format: int32
   * @description The minimum quantity of orderUnits that must be ordered to buy a product.
   */
  minQuantity: number
  /**
   * Format: int32
   * @description The number of contentUnits that belong to an orderUnit, e.g 6 bottles (contentUnit) in 1 six-pack (orderUnit). The user buys orderUnits, and these contain contentUnits.
   */
  numberContentUnitPerOrderUnit: number
  /** @description Unit in which the product can be ordered, e.g "six-pack" */
  orderUnit?: string
  /**
   * Format: int32
   * @description The amount of orderUnits for the given price.
   */
  priceQuantity: number
  /**
   * Format: int32
   * @description The amount of orderUnits that the supplier delivers in one single order.
   */
  supplierPackingQuantity: number
}
/** PageableResponseOfMediumProduct */
type PageableResponseOfMediumProduct = {
  items: MediumProduct[]
  /** Format: int32 */
  pageCount: number
  /** Format: int32 */
  totalCount: number
}
/** PageableResponseOfOrder */
type PageableResponseOfOrder = {
  items: Order[]
  /** Format: int32 */
  pageCount: number
  /** Format: int32 */
  totalCount: number
}
/** PageableResponseOfOrderPreview */
type PageableResponseOfOrderPreview = {
  items: OrderPreview[]
  /** Format: int32 */
  pageCount: number
  /** Format: int32 */
  totalCount: number
}
/** PageableResponseOfShopCollection */
type PageableResponseOfShopCollection = {
  items: ShopCollection[]
  /** Format: int32 */
  pageCount: number
  /** Format: int32 */
  totalCount: number
}
/** PageableResponseOfShortQuote */
type PageableResponseOfShortQuote = {
  items: ShortQuote[]
  /** Format: int32 */
  pageCount: number
  /** Format: int32 */
  totalCount: number
}
/** PasswordChangeDTO */
type PasswordChangeDTO = {
  currentPassword: string
  newPassword: string
}
/** PasswordResetDTO */
type PasswordResetDTO = {
  mail: string
}
/** Permission */
type Permission = {
  /**
   * @description This gives the user the right to switch between subsidiaries
   * @example false
   */
  changeSubsidiary?: boolean
  isApprover: boolean
  /**
   * Format: bigdecimal
   * @description This is the limit of the order value which can be ordered without approval.
   */
  orderLimit?: number
  /**
   * @description The administrator has permission to alter the rights of other users including himself
   * @example false
   */
  shopAdmin: boolean
  /**
   * @description Has the right to submit a prepared order. This right does not include the creation of orders, as this is a common right.
   * @example false
   */
  submitOrder: boolean
}
/** PermissionPatch */
type PermissionPatch = {
  /**
   * @description Determines if this user has the right to switch subsidiaries
   * @example false
   */
  changeSubsidiary?: boolean
  shopAdmin?: boolean
  /**
   * @description Determines if this user has the right to order
   * @example false
   */
  submitOrder?: boolean
}
/** PortalSettings */
type PortalSettings = {
  addressPermissions: AddressPermissions
  /**
   * @description Flag indicates if prices include taxes.
   * @example false
   */
  grossPortal: boolean
  /**
   * @description Flag indicates if the user may input an order number.
   * @example false
   */
  isCustomOrderNumberEnabled: boolean
  /**
   * @description Determines if every user sees his or her personal cart
   * @example false
   */
  isUserSpecificCartEnabled: boolean
  /**
   * @description Determines if every user sees only their collections
   * @example false
   */
  isUserSpecificCollectionsEnabled: boolean
  maxFileUploadSize?: string
  /**
   * @description Flag indicates if minimum order surcharges are applied once per cart, independent of the number of supplier carts.
   * @example false
   */
  minimumOrderSurchargesOnlyApplyOncePerCart: boolean
  sellerComments: SellerComments
  /**
   * @description Flag indicates if shipping costs are applied once per cart, independent of the number of supplier carts.
   * @example false
   */
  shippingCostsOnlyApplyOncePerCart: boolean
  /**
   * @description Flag indicates if logout option is visible.
   * @example false
   */
  showLogout: boolean
  /**
   * @description Flag indicates if users may access the order limit options.
   * @example false
   */
  showOrderLimitFunctionality: boolean
  /**
   * @description Flag indicates if product IDs are visible, e.g. on the product page.
   * @example false
   */
  showProductIds: boolean
  /**
   * @description Flag indicates if suppliers are visible e.g. on the product page.
   * @example false
   */
  showSuppliers: boolean
  supplierComment: SupplierComments
  /**
   * @description True, if this portal has the Apple CTO Finder activated
   * @example false
   */
  useAppleCtoFinder: boolean
  /**
   * @description Flag indicates if users are only allowed to use their personally created addresses.
   * @example false
   */
  useOwnDeliveryAddress: boolean
  /**
   * @description Flag indicates if promotional products are available. Can be changed on ITscope platform.
   * @example false
   */
  usePromotionalProducts: boolean
  /**
   * @description Flag indicates if quotes are available. Can be changed on ITscope platform.
   * @example false
   */
  useQuotes: boolean
  /**
   * Format: bigdecimal
   * @description Value added tax of the portal, e.g. 19 for germany.
   */
  vatRatePercent: number
}
/** ProductClass */
type ProductClass = {
  ean: string
  manufacturerMaterialNo: string
  materialNo: string
  productClassDescription: string
  /** Format: int32 */
  productClassId: number
  productClassPicUrl: string
}
/** ProductClassesPostDTO */
type ProductClassesPostDTO = {
  baseModel: BaseModelReq
  modelComponents: ModelComponentReq[]
}
/** ProductFilterResponse */
type ProductFilterResponse = {
  attributeIdentifiers: FilterIdentifier[]
  filterIdentifiers: FilterIdentifier[]
}
/** ProductPriceUpdate */
type ProductPriceUpdate = {
  price?: SimplePrice
  /** Format: int64 */
  productId: number
  stock?: SimpleStock
  suppliers: SupplierRow[]
}
/** ProductType */
type ProductType = {
  /** Format: int64 */
  id: number
  name: string
}
/** PromotionalProduct */
type PromotionalProduct = {
  /** @description Date in ISO-8601 format in UTC timezone */
  endDate: string
  marketingText: string
  oldPrice: Money
  product: ShortProduct
}
/** ProposeOrderPostDTO */
type ProposeOrderPostDTO = {
  billingAddress: ShopAddress
  billingContactData?: ContactData
  /** @description Comment which the approvers will see in the email */
  commentToApprovers?: string
  /** @description End customer order id defined by the buyer */
  customerOrderId?: string
  deliveryAddress: ShopAddress
  deliveryContactData?: ContactData
  orderComment?: string
  /** @description Order number defined by the buyer or generated manually. Unique between buyer and seller. */
  orderNumber?: string
  /** @description Use ITscope-generated timestamp of the order you want to update */
  timestamp: string
  usePartialShipment?: boolean
}
/** PunchoutForm */
type PunchoutForm = {
  eprocurementUrl: string
  items: PunchoutItem[]
  payload: string
  target: string
}
/** PunchoutItem */
type PunchoutItem = {
  displayName: string
  /** Format: bigdecimal */
  priceForOne: number
  /** Format: int64 */
  puid: number
  /** Format: int32 */
  quantity: number
}
/** Quote */
type Quote = {
  id: string
  name: string
  orderId?: string
  quote?: FullQuote
  /** @enum {string} */
  quoteStatus: QuoteStatus
  quoteVersion: string
  request?: QuoteRequest
  /** @description Use ITscope-generated timestamp of the quote you want to update */
  timestamp: string
}
/** QuoteActivity */
type QuoteActivity = {
  id: string
  message: string
  /** @description Use ITscope-generated timestamp of the quote you want to update */
  timestamp: string
  /** @enum {string} */
  type: QuoteActivityType
  user: QuoteActivityUser
}
/** QuoteActivityUser */
type QuoteActivityUser = {
  avatarUrl?: string
  fullName: string
}
/** QuoteId */
type QuoteId = {
  id: string
}
/** QuoteItem */
type QuoteItem = {
  discount?: Discount
  hasDatasheet: boolean
  id: string
  isInShopAssortment: boolean
  /**
   * @description Is this an optional item to be picked?
   * @example false
   */
  isOptional: boolean
  /**
   * @description Optional item that is picked in the final quote.
   * @example false
   */
  isPickedOptional: boolean
  isQuantityEditCustomer: boolean
  price?: SimplePrice
  /** Format: int64 */
  productId?: number
  productImageUrls: string[]
  productTitle: string
  /** Format: int32 */
  quantity: number
  totalPrice?: Money
  /** Format: int64 */
  uniqueId?: number
  /**
   * Format: bigdecimal
   * @description Value added tax percentage e.g. 19 for germany
   */
  vatPercent?: number
}
/** QuoteItemUpdate */
type QuoteItemUpdate = {
  isPickedOptional?: boolean
  /** Format: int32 */
  quantity?: number
  /** @description Use ITscope-generated timestamp of the quote you want to update */
  timestamp: string
}
/** QuoteRequest */
type QuoteRequest = {
  author: ShortUser
  comment?: string
  /** @description Date in ISO-8601 format in UTC timezone */
  createdDate: string
  id: string
  items: QuoteItem[]
  sum: Money
}
/** QuoteRequestForProduct */
type QuoteRequestForProduct = {
  /**
   * @description Is true if the product is in the request.
   * @example false
   */
  hasProduct: boolean
  id: string
  name: string
}
/** QuoteRequestItemDTO */
type QuoteRequestItemDTO = {
  comment?: string
  /** Format: int32 */
  quantity?: number
}
/** QuoteRequestPatch */
type QuoteRequestPatch = {
  comment?: string
  name?: string
}
/** QuoteRequestPostDTO */
type QuoteRequestPostDTO = {
  items?: ShortQuoteRequestItem[]
  name: string
}
/** QuoteUpdate */
type QuoteUpdate = {
  buyerAddress?: ShopAddressReq
  buyerAddressId?: string
  buyerContact?: ContactData
  /** @description Use ITscope-generated timestamp of the quote you want to update */
  timestamp: string
}
/** RejectionDTO */
type RejectionDTO = {
  comment: string
}
/** RejectionInformation */
type RejectionInformation = {
  date: string
  rejectedByUser: ShortUserRes
  rejectionComment?: string
}
/** RequestOrderPostDTO */
type RequestOrderPostDTO = {
  /** @description Comment which the approvers will see in the email */
  commentToApprovers?: string
}
/** RuntimeRequest */
type RuntimeRequest = {
  /** Format: int64 */
  productId?: number
  /**
   * Format: int32
   * @description Scale (amount) of products
   */
  scale: number
}
/** SSOSettings */
type SSOSettings = {
  ssoLoginExclusive: boolean
  url: string
}
/** Scale */
type Scale = {
  /** Format: int32 */
  minScale: number
  resellPrice: Money
}
/** SelectableModelComponent */
type SelectableModelComponent = {
  /** Format: int32 */
  componentId: number
  /** Format: double */
  componentListPrice: number
  description: string
  selected: boolean
}
/** SelfRegistration */
type SelfRegistration = {
  company?: Company
  customTermsConfirmation: boolean
  password: string
  person: Contact
  termsConfirmation: boolean
}
/** SellerComments */
type SellerComments = {
  field1?: CommentField
  field2?: CommentField
  field3?: CommentField
  field4?: CommentField
  field5?: CommentField
  field6?: CommentField
}
/** SendCollectionPostDTO */
type SendCollectionPostDTO = {
  emails: string[]
  subject?: string
  withAttachment: boolean
}
/** SendPreparedOrderDTO */
type SendPreparedOrderDTO = {
  /** @description Use ITscope-generated timestamp of the order you want to send */
  timestamp: string
}
/** SerialNumber */
type SerialNumber = {
  type?: string
  value: string
}
/** Setting */
type Setting = {
  /** @enum {string} */
  preferedProductSearchView: PreferedProductSearchView
}
/** SettingPatch */
type SettingPatch = {
  /** @enum {string} */
  preferedProductSearchView?: PreferedProductSearchView
}
/** ShopAddress */
type ShopAddress = {
  addition1?: string
  addition2?: string
  /**
   * @description Flag that denotes whether the user is allowed to edit this address.
   * @example false
   */
  canEdit: boolean
  city: string
  clientNumber?: string
  companyName: string
  email: string
  id: string
  /**
   * @description Flag that denotes whether this address is the default billing address
   * @example false
   */
  isDefaultBillingAddress: boolean
  /**
   * @description Flag that denotes whether this address should be set as default delivery address
   * @example false
   */
  isDefaultDeliveryAddress: boolean
  /**
   * @description Flag that denotes whether this address is the primary address
   * @example false
   */
  isPrimaryAddress: boolean
  /**
   * @description Country code according to the ISO 3166-1 alpha 3 standard.
   * @example DEU
   */
  iso3country: string
  /**
   * @description Telephone number headed by country code.
   * @example +49 123456789
   */
  phone?: string
  postBox?: string
  street: string
  url?: string
  /** @description The portal user who created the address */
  user?: ShortUser
  zipCode: string
}
/** ShopAddressDTO */
type ShopAddressDTO = {
  addition1?: string
  addition2?: string
  city?: string
  clientNumber?: string
  companyName?: string
  email?: string
  /**
   * @description Flag that denotes whether this address should be set as default billing address
   * @example false
   */
  isDefaultBillingAddress?: boolean
  /**
   * @description Flag that denotes whether this address should be set as default delivery address
   * @example false
   */
  isDefaultDeliveryAddress?: boolean
  /**
   * @description Country code according to the ISO 3166-1 alpha 3 standard.
   * @example DEU
   */
  iso3country?: string
  /**
   * @description Telephone number headed by country code.
   * @example +49 123456789
   */
  phone?: string
  postBox?: string
  street?: string
  url?: string
  zipCode?: string
}
/** ShopAddressReq */
type ShopAddressReq = {
  addition1?: string
  addition2?: string
  /**
   * @description Flag that denotes whether the user is allowed to edit this address.
   * @example false
   */
  canEdit: boolean
  city: string
  clientNumber?: string
  companyName: string
  email: string
  id: string
  /**
   * @description Flag that denotes whether this address is the default billing address
   * @example false
   */
  isDefaultBillingAddress: boolean
  /**
   * @description Flag that denotes whether this address should be set as default delivery address
   * @example false
   */
  isDefaultDeliveryAddress: boolean
  /**
   * @description Flag that denotes whether this address is the primary address
   * @example false
   */
  isPrimaryAddress: boolean
  /**
   * @description Country code according to the ISO 3166-1 alpha 3 standard.
   * @example DEU
   */
  iso3country: string
  /**
   * @description Telephone number headed by country code.
   * @example +49 123456789
   */
  phone?: string
  postBox?: string
  street: string
  url?: string
  /** @description The portal user who created the address */
  user?: ShortUserReq
  zipCode: string
}
/** ShopAddressRes */
type ShopAddressRes = {
  addition1?: string
  addition2?: string
  /**
   * @description Flag that denotes whether the user is allowed to edit this address.
   * @example false
   */
  canEdit: boolean
  city: string
  clientNumber?: string
  companyName: string
  email: string
  id: string
  /**
   * @description Flag that denotes whether this address is the default billing address
   * @example false
   */
  isDefaultBillingAddress: boolean
  /**
   * @description Flag that denotes whether this address should be set as default delivery address
   * @example false
   */
  isDefaultDeliveryAddress: boolean
  /**
   * @description Flag that denotes whether this address is the primary address
   * @example false
   */
  isPrimaryAddress: boolean
  /**
   * @description Country code according to the ISO 3166-1 alpha 3 standard.
   * @example DEU
   */
  iso3country: string
  /**
   * @description Telephone number headed by country code.
   * @example +49 123456789
   */
  phone?: string
  postBox?: string
  street: string
  url?: string
  /** @description The portal user who created the address */
  user?: ShortUserRes
  zipCode: string
}
/** ShopCollection */
type ShopCollection = {
  assignee?: ShortUser
  /** @description Date in ISO-8601 format in UTC timezone */
  createdDate: string
  id: string
  isArchived: boolean
  /** Format: int32 */
  itemCount: number
  /** @description Date in ISO-8601 format in UTC timezone */
  lastModifiedDate?: string
  name: string
}
/** ShortProduct */
type ShortProduct = {
  articleNumber: ArticleNumber
  /** @enum {string} */
  condition?: Condition
  imageUrl?: string
  price?: SimplePrice
  /** Format: int64 */
  productId: number
  productType: ProductType
  stock?: SimpleStock
  title: string
}
/** ShortQuote */
type ShortQuote = {
  /**
   * @description Flag indicates if user already viewed quote.
   * @example false
   */
  alreadyViewed: boolean
  assignee?: ShortUserRes
  /**
   * @description Flag indicates if buyer archived the quote.
   * @example false
   */
  buyerArchived: boolean
  /** @description Date in ISO-8601 format in UTC timezone */
  createdDate: string
  id: string
  /**
   * @description Flag indicates if price already includes taxes.
   * @example false
   */
  isGrossQuote: boolean
  /** Format: int32 */
  itemCount: number
  name: string
  quoteNumber: string
  /** @enum {string} */
  quoteStatus: QuoteStatus
  /** @description Sum of all item prices in the quote. */
  sum: Money
}
/** ShortQuoteRequestItem */
type ShortQuoteRequestItem = {
  label?: string
  /** Format: int64 */
  productId?: number
  /** Format: int32 */
  quantity?: number
}
/** ShortUser */
type ShortUser = {
  avatarUrl?: string
  companyName: string
  /** @enum {string} */
  department: Department
  email?: string
  firstName?: string
  id: string
  lastName?: string
  userRegistered: boolean
}
/** ShortUserReq */
type ShortUserReq = {
  avatarUrl?: string
  companyName: string
  /** @enum {string} */
  department: Department
  email?: string
  firstName?: string
  id: string
  lastName?: string
  userRegistered?: boolean
}
/** ShortUserRes */
type ShortUserRes = {
  avatarUrl?: string
  companyName: string
  /** @enum {string} */
  department: Department
  email?: string
  firstName?: string
  id: string
  lastName?: string
  userRegistered: boolean
}
/** SimplePrice */
type SimplePrice = {
  packagingModel?: PackagingModel
  purchasePrice?: Money
  resellPrice: Money
  subscriptionModel?: SubscriptionModel
  /** Format: int64 */
  supplierRowId?: number
}
/** SimpleStock */
type SimpleStock = {
  /** Format: int32 */
  count: number
  deliveryDate?: string
  /** Format: int32 */
  incomingAmount?: number
  isUnlimitedStock: boolean
  /** @enum {string} */
  status: SimpleStockStatus
}
/** SliceOfMediumProduct */
type SliceOfMediumProduct = {
  hasNext: boolean
  items: MediumProduct[]
  /** Format: int32 */
  lastKey: number
}
/** StartPage */
type StartPage = {
  /** @description List of featured products. Empty when individual or categories are shown. */
  featuredProducts: ShortProduct[]
  /** @description Custom set text to display. Can contain HTML. */
  htmlText?: string
  /** @enum {string} */
  startPageType: StartPageType
}
/** SubscriptionModel */
type SubscriptionModel = {
  /**
   * Format: int32
   * @description Determines how many billingPeriodUnits lie between bills.
   */
  billingPeriod?: number
  /**
   * @description Unit of time that is multiplied with the billingPeriod to determine how frequent the buyer will be billed.
   * @enum {string}
   */
  billingPeriodUnit?: BillingPeriodUnit
  /**
   * Format: int32
   * @description Determines for how many subscriptionPeriodUnits a subscription lasts.
   */
  subscriptionPeriod?: number
  /**
   * @description Unit of time that is multiplied with the subscriptionPeriod to determine how long the subscription lasts.
   * @enum {string}
   */
  subscriptionPeriodUnit?: BillingPeriodUnit
}
/** Subsidiary */
type Subsidiary = {
  id: string
  name: string
}
/** SupplierCart */
type SupplierCart = {
  /**
   * @description Is it possible to order this supplier cart? Otherwise, the contents of this supplier cart an only be ordered by ordering the whole cart.
   * @enum {string}
   */
  canOrder: CanOrder
  cartInfo: CartInfo
  cartItems: CartItem[]
  id: string
  /**
   * Format: int32
   * @description Maximum length of comment for a cart item
   */
  maxPositionCommentLength: number
  /** @description Explanations why ordering may be forbidden and other warnings for the user. */
  messages: CartMessage[]
  supplierName?: string
  /** @description ITscope-generated timestamp to be used for cart updates */
  timestamp: string
}
/** SupplierComments */
type SupplierComments = {
  field1: CommentField
}
/** SupplierRow */
type SupplierRow = {
  /** @enum {string} */
  condition?: Condition
  /** Format: int64 */
  id: number
  price?: SimplePrice
  scales: Scale[]
  stock?: SimpleStock
  supplierName: string
  supplierPid?: string
}
/** TechnicalDetail */
type TechnicalDetail = {
  group: string
  name: string
  value: string
}
/** TextContent */
type TextContent = {
  contentCategory: string
  value: string
}
/** Token */
type Token = {
  value: string
}
/** TotalTaxInfo */
type TotalTaxInfo = {
  /**
   * Format: bigdecimal
   * @description Value added tax percentage e.g. 19 for germany
   */
  vatPercent: number
  vatSum: Money
}
/** TrackingLink */
type TrackingLink = {
  packageId: string
  url?: string
}
/** UpdateOrderItemDTO */
type UpdateOrderItemDTO = {
  comment?: string
  /** Format: int32 */
  quantity?: number
  sellerComment?: string
  /** @description Use ITscope-generated timestamp of the order you want to update */
  timestamp: string
}
/** UpdateOrderPatchDTO */
type UpdateOrderPatchDTO = {
  billingAddress?: ShopAddressReq
  billingContactData?: ContactDataReq
  comment?: string
  customerOrderId?: string
  deliveryAddress?: ShopAddressReq
  deliveryContactData?: ContactDataReq
  hasPartialShipment?: boolean
  isArchived?: boolean
  /** @description Order number defined by the buyer or generated manually. Unique between buyer and seller. */
  orderNumber?: string
  /** @description Use ITscope-generated timestamp of the order you want to update */
  timestamp: string
}
/** User */
type User = {
  /** @description 2 letter language code according to ISO 639-1 */
  alpha2language: string
  avatarUrl?: string
  companyName: string
  /** @enum {string} */
  department: Department
  email: string
  firstName: string
  id: string
  lastName: string
  permission: Permission
  phone?: string
  phoneMobile?: string
  setting: Setting
  /** @enum {string} */
  title: Title
}
/** UserPatchDTO */
type UserPatchDTO = {
  /** @description 2 letter language code according to ISO 639-1 */
  alpha2language?: string
  /** @enum {string} */
  department?: Department
  firstName?: string
  lastName?: string
  phone?: string
  phoneMobile?: string
  /** @enum {string} */
  title?: Title
}
/** ValidTokenDTO */
type ValidTokenDTO = {
  /** @enum {string} */
  contactTitle?: Title
  firstName?: string
  lastName?: string
}
/** ValidateOrderNumberDTO */
type ValidateOrderNumberDTO = {
  /** @description Order number that needs its uniqueness validated */
  orderNumber: string
}
enum PathsStorefrontAddressesValidateTypePostParametersPathType {
  billing = 'billing',
  delivery = 'delivery'
}
enum PathsStorefrontCollectionsGetParametersQuerySort {
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC',
  LAST_MODIFIED = 'LAST_MODIFIED',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC'
}
enum PathsStorefrontCollectionsActionsToCartPostResponses200ContentApplicationJson {
  FAILURE = 'FAILURE',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  SUCCESS = 'SUCCESS'
}
enum PathsStorefrontEmailsValidatePostResponses200ContentApplicationJson {
  BLACKLISTED = 'BLACKLISTED',
  INVALID = 'INVALID',
  INVITED = 'INVITED',
  REGISTERED = 'REGISTERED',
  VALID = 'VALID'
}
enum PathsStorefrontOrdersGetParametersQuerySort {
  CREATED_DESC = 'CREATED_DESC',
  ID = 'ID',
  LAST_MODIFIED = 'LAST_MODIFIED',
  SENT_ASC = 'SENT_ASC',
  SENT_DESC = 'SENT_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  SUPPLIER = 'SUPPLIER'
}
enum PathsStorefrontProductsGetParametersQuerySort {
  AVAILABILITY = 'AVAILABILITY',
  CALC_PRICE_ASC = 'CALC_PRICE_ASC',
  CALC_PRICE_DESC = 'CALC_PRICE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  POPULARITY = 'POPULARITY',
  RELEVANCE = 'RELEVANCE',
  STOCK = 'STOCK'
}
enum PathsStorefrontQuotesGetParametersQuerySort {
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC',
  LAST_MODIFIED = 'LAST_MODIFIED',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC_ORGANISATION_ASC_ORGANISATION_DESC = 'TITLE_DESC    ORGANISATION_ASC    ORGANISATION_DESC',
  VALID_ASC = 'VALID_ASC',
  VALID_DESC = 'VALID_DESC'
}
enum OptimizationStrategy {
  ADDITIONAL_SHOP_OPTIMIZATION = 'ADDITIONAL_SHOP_OPTIMIZATION',
  MANUAL = 'MANUAL',
  MINPRICE = 'MINPRICE',
  MINSUPPLIERS = 'MINSUPPLIERS'
}
enum ApprovalRequestStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}
enum SimpleStockStatus {
  Available = 'Available',
  NotAvailable = 'NotAvailable',
  OnOrder = 'OnOrder',
  OnStock = 'OnStock',
  Ordered = 'Ordered',
  Unknown = 'Unknown',
  Warehouse = 'Warehouse'
}
enum ContentType {
  PDF = 'PDF',
  XLS = 'XLS'
}
enum CanOrder {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  FORBIDDEN = 'FORBIDDEN'
}
enum Condition {
  BUILD_TO_ORDER = 'BUILD_TO_ORDER',
  BULK = 'BULK',
  B_STOCK = 'B_STOCK',
  DEMONSTRATION_MODEL = 'DEMONSTRATION_MODEL',
  FACTORY_REFURBISHED = 'FACTORY_REFURBISHED',
  NEW = 'NEW',
  NEWPULL = 'NEWPULL',
  NOT_FOR_RESALE = 'NOT_FOR_RESALE',
  REFURBISHED = 'REFURBISHED',
  RMA_STOCK = 'RMA_STOCK',
  USED = 'USED',
  USEDPULL = 'USEDPULL'
}
enum Reason {
  MinOrderValue = 'MinOrderValue',
  Other = 'Other'
}
enum CartMessageType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING'
}
enum QuoteActivityType {
  CHAT = 'CHAT',
  HISTORY = 'HISTORY'
}
enum OrderLimitExceedance {
  EXCEEDED = 'EXCEEDED',
  KEPT = 'KEPT',
  NO_ORDER_RIGHT = 'NO_ORDER_RIGHT'
}
enum Title {
  Diverse = 'Diverse',
  Female = 'Female',
  Male = 'Male',
  Unspecified = 'Unspecified'
}
enum OrderStatus {
  ADVISED = 'ADVISED',
  ARRIVED = 'ARRIVED',
  ARRIVED_WARNING = 'ARRIVED_WARNING',
  BLOCKED = 'BLOCKED',
  BLOCKED_BYLIMIT = 'BLOCKED_BYLIMIT',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  INVOICED = 'INVOICED',
  ORDERED_ELSEWHERE = 'ORDERED_ELSEWHERE',
  PARTIALLY_ADVISED = 'PARTIALLY_ADVISED',
  PARTIALLY_INVOICED = 'PARTIALLY_INVOICED',
  PARTIALLY_PROCESSING = 'PARTIALLY_PROCESSING',
  PAYMENT_AWAITING = 'PAYMENT_AWAITING',
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  PORTAL_APPROVAL_DENIED = 'PORTAL_APPROVAL_DENIED',
  PREPARING = 'PREPARING',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  SENDING_ERROR = 'SENDING_ERROR',
  SENT = 'SENT',
  TRANSMITTING = 'TRANSMITTING'
}
enum Relation {
  BRANCH = 'BRANCH',
  NONE = 'NONE',
  OWNER = 'OWNER'
}
enum OrderLimitType {
  DEFAULT = 'DEFAULT',
  UNLIMITED = 'UNLIMITED',
  USER = 'USER'
}
enum TransactionType {
  DELIVERY = 'DELIVERY',
  INVOICE = 'INVOICE',
  ORDER = 'ORDER',
  ORDERSTATUS = 'ORDERSTATUS',
  PAYMENT = 'PAYMENT'
}
enum QuoteStatus {
  ACCEPTED = 'ACCEPTED',
  DRAFT = 'DRAFT',
  NEGOTIATION = 'NEGOTIATION',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED'
}
enum PreferedProductSearchView {
  CARDS = 'CARDS',
  LIST = 'LIST'
}
enum Department {
  Accounting = 'Accounting',
  Extern = 'Extern',
  IT = 'IT',
  Management = 'Management',
  Marketing = 'Marketing',
  Other = 'Other',
  Purchasing = 'Purchasing',
  RegistrationPending = 'RegistrationPending',
  Sales = 'Sales',
  ServiceSupport = 'ServiceSupport'
}
enum StartPageType {
  categories = 'categories',
  individual = 'individual',
  popular = 'popular',
  productList = 'productList'
}
enum BillingPeriodUnit {
  CENTURIES = 'CENTURIES',
  DAYS = 'DAYS',
  DECADES = 'DECADES',
  ERAS = 'ERAS',
  FOREVER = 'FOREVER',
  HALF_DAYS = 'HALF_DAYS',
  HOURS = 'HOURS',
  MICROS = 'MICROS',
  MILLENNIA = 'MILLENNIA',
  MILLIS = 'MILLIS',
  MINUTES = 'MINUTES',
  MONTHS = 'MONTHS',
  NANOS = 'NANOS',
  SECONDS = 'SECONDS',
  WEEKS = 'WEEKS',
  YEARS = 'YEARS'
}

export {
  AccessoryGroup,
  AddressPermissions,
  AddressSelection,
  ApprovalRequest,
  ArticleNumber,
  Attachment,
  AuthTokens,
  BaseModelReq,
  BaseModelRes,
  BaseModelResource,
  Branding,
  BundleItemProduct,
  BundleReference,
  Cart,
  CartInfo,
  CartItem,
  CartItemMoveDTO,
  CartItemMoveInfo,
  CartItemPostDTO,
  CartItemSellerCommentReq,
  CartItemSellerCommentRes,
  CartItemsCount,
  CartMessage,
  CartOptimizationResult,
  CartPatchDTO,
  Category,
  ChangePasswordDTO,
  Checkout,
  CheckoutAddress,
  CheckoutAddressValidation,
  CollectionId,
  CollectionItem,
  CollectionItemPatchDTO,
  CollectionItemPostDTO,
  CollectionName,
  CollectionPatchDTO,
  Comment,
  CommentDTO,
  CommentField,
  Company,
  Contact,
  ContactData,
  ContactDataReq,
  ContactDataRes,
  ContactUser,
  CustomTerms,
  Datasheet,
  DefaultOrderLimit,
  DefaultOrderLimitPatch,
  DeliveryInfo,
  DetailedOrder,
  DetailedShopCollection,
  Discount,
  Email,
  EmailPostDTO,
  EmailPreview,
  EmailsPostDTO,
  EmailsResponse,
  FilterIdentifier,
  FilterValue,
  FluxOfServerSentEventOfListOfQuoteActivity,
  FluxOfServerSentEventOfQuote,
  FullProduct,
  FullQuote,
  ImpersonationStatus,
  InvitationRegistration,
  Manufacturer,
  MediumProduct,
  ModelComponentGroup,
  ModelComponentReq,
  ModelComponentRes,
  ModelConfiguration,
  ModelGroup,
  ModelGroupResource,
  Money,
  NewQuotesCount,
  OptimizationType,
  Order,
  OrderAddress,
  OrderItem,
  OrderLimitUser,
  OrderLimitUserPatch,
  OrderPostDTO,
  OrderPreview,
  OrderRejectionDTO,
  OrderResponse,
  OrderSearchModel,
  OrderSumInformation,
  OrderTransaction,
  OrderTransactions,
  OrderingConditions,
  PackagingModel,
  PageableResponseOfMediumProduct,
  PageableResponseOfOrder,
  PageableResponseOfOrderPreview,
  PageableResponseOfShopCollection,
  PageableResponseOfShortQuote,
  PasswordChangeDTO,
  PasswordResetDTO,
  Permission,
  PermissionPatch,
  PortalSettings,
  ProductClass,
  ProductClassesPostDTO,
  ProductFilterResponse,
  ProductPriceUpdate,
  ProductType,
  PromotionalProduct,
  ProposeOrderPostDTO,
  PunchoutForm,
  PunchoutItem,
  Quote,
  QuoteActivity,
  QuoteActivityUser,
  QuoteId,
  QuoteItem,
  QuoteItemUpdate,
  QuoteRequest,
  QuoteRequestForProduct,
  QuoteRequestItemDTO,
  QuoteRequestPatch,
  QuoteRequestPostDTO,
  QuoteUpdate,
  RejectionDTO,
  RejectionInformation,
  RequestOrderPostDTO,
  RuntimeRequest,
  SSOSettings,
  Scale,
  SelectableModelComponent,
  SelfRegistration,
  SellerComments,
  SendCollectionPostDTO,
  SendPreparedOrderDTO,
  SerialNumber,
  Setting,
  SettingPatch,
  ShopAddress,
  ShopAddressDTO,
  ShopAddressReq,
  ShopAddressRes,
  ShopCollection,
  ShortProduct,
  ShortQuote,
  ShortQuoteRequestItem,
  ShortUser,
  ShortUserReq,
  ShortUserRes,
  SimplePrice,
  SimpleStock,
  SliceOfMediumProduct,
  StartPage,
  SubscriptionModel,
  Subsidiary,
  SupplierCart,
  SupplierComments,
  SupplierRow,
  TechnicalDetail,
  TextContent,
  Token,
  TotalTaxInfo,
  TrackingLink,
  UpdateOrderItemDTO,
  UpdateOrderPatchDTO,
  User,
  UserPatchDTO,
  ValidTokenDTO,
  ValidateOrderNumberDTO,
  PathsStorefrontAddressesValidateTypePostParametersPathType,
  PathsStorefrontCollectionsGetParametersQuerySort,
  PathsStorefrontCollectionsActionsToCartPostResponses200ContentApplicationJson,
  PathsStorefrontEmailsValidatePostResponses200ContentApplicationJson,
  PathsStorefrontOrdersGetParametersQuerySort,
  PathsStorefrontProductsGetParametersQuerySort,
  PathsStorefrontQuotesGetParametersQuerySort,
  OptimizationStrategy,
  ApprovalRequestStatus,
  SimpleStockStatus,
  ContentType,
  CanOrder,
  Condition,
  Reason,
  CartMessageType,
  QuoteActivityType,
  OrderLimitExceedance,
  Title,
  OrderStatus,
  Relation,
  OrderLimitType,
  TransactionType,
  QuoteStatus,
  PreferedProductSearchView,
  Department,
  StartPageType,
  BillingPeriodUnit
}
